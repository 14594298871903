<template>
  <div>
    <!--info-->
    <div class="info">
      <div class="info__top">
        <p>利用規約</p>
        <a @click.prevent="redirectURL('/term')">
          別タブで開いて読む
          <ChevronRightIcon size="24"/>
        </a>
      </div>

      <div class="info__content">
        <div class="block">
          <p>ChibiJob 利用規約</p>
          <h3>第1条 目的</h3>
          <p>本規約は株式会社ディー・エム広告社（以下「当社」という）が提供する、ポイントサービス（以下、「本サービス」という）を利用する会員が、本サービスを利用する上で順守すべき事項を定めるものとします。</p>
          <h3>第2条 定義</h3>
          <ul>
            <li>1.「会員」とは、本規約に同意の上、入会を申し込まれ、所定の手続きを完了された、当社によって本サービスの利用を認められた個人をいいます。</li>
            <li>2.「ポイント」とは、本サービスにおいて会員に対して付与される特典ポイントのことをいいます。</li>
            <li>3.「ポイント提携サイト」とは、本サービスと提携し、一定の成果のもと本サービス会員に対してポイントを付与する企業、または当該企業が運営するサイトや店舗をいいます。</li>
            <li>4.「成果」とは、本サービスにおいて、当社およびポイント提携サイトが定める一定の条件を満たす行為をいいます。</li>
            <li>5.「ポイントの交換」とは、当社が付与したポイントを、当社が定めるポイント交換先パートナーが発行するポイント、サービス、商品等と、当社が定める規定に従って交換することをいいます。</li>
          </ul>
          <h3>第3条 入会登録</h3>
          <p>
            本サービスの入会希望者は、当社が定める手続きに従って、登録に必要な情報を当社に届け出るものとします。入会登録は一個人一登録とします。
          </p>
          <h3>第4条 同意</h3>
          <p>
            本サービスの入会希望者は、本規約に同意した上で入会の登録を行い、本サービスの入会登録および利用は本規約に同意したものとみなします｡
          </p>
          <h3>第5条 会員資格</h3>
          <p>
            当社は入会申し込みをしたものが以下のいずれかに該当する場合、その入会を承諾しないことがあります。また、申込承諾後であっても、会員が以下のいずれかの項目に該当すると判明した場合、当社は会員資格を停止または取り消すことが出来るものとします。会員資格が取消となった場合、取消時に当該会員が保有するポイントやかかる一切の権利を失うものとします。また、当社は、入会時点まで遡りポイント交換によって生じた当社負担相当額を請求できるものとします。なお、本項で定める取消によって、入会希望者または会員に発生した損害について、当社は一切の責任を負わず、また、会員資格の停止または取り消しの理由を開示する義務を負わないものとします。
          </p>
          <ul>
            <li>① 実在しない者の場合</li>
            <li>② 入会登録者が12歳未満の場合</li>
            <li>③ 入会申込者が18歳未満で保護者の同意を得ていなかった場合</li>
            <li>④ 入会申込者が入会申込の時点で本サービスの資格停止処分を受けている、又は過去に本規約違反等で会員資格の取消処分を受けたことがある場合</li>
            <li>⑤ 他人又は架空の個人情報を使って入会申込を行う場合（第三者からの委任・委託を受けている場合も含む）</li>
            <li>⑥ 入会申込内容に虚偽、誤記、記入漏れ、又は入力ミス等がある場合</li>
            <li>⑦ 個人でない場合</li>
            <li>⑧ 反社会的勢力の団体員及び団体関係者に該当する場合</li>
            <li>⑨ 入会申込者がすでに入会済みであり、二重登録の入会申込を行った場合</li>
            <li>⑩ その他、当社が不適当と判断する場合</li>
          </ul>
          <h3>第6条 会員情報</h3>
          <ul>
            <li>
              1. 当社は、会員よりご提供いただきました個人情報は、以下の目的でのみ利用いたします。
              <ul>
                <li>① お問い合わせに対する回答及び資料送付のご連絡</li>
                <li>② 当社のお客様向けサービスの提供</li>
                <li>③ 当社のお客様向けサービスの利用状況の分析</li>
                <li>④ 本人確認</li>
                <li>⑤ サービスの開発・改善のための分析</li>
                <li>⑥ 当社のお客様向けサービスに関する広告の配信のため</li>
              </ul>
            </li>

            <li>
              2. 当社は、次に掲げる場合を除くほか、あらかじめご本人の同意を得ないで、会員の個人情報を第三者に提供しません。
              <ul>
                <li>① 法令に基づく場合</li>
                <li>② 人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難で あるとき</li>
                <li>③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を 得ることが困難であるとき</li>
                <li>④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
              </ul>
            </li>

            <li>
              ただし次に掲げる場合は上記に定める第三者には該当しません。
              <ul>
                <li>① 商品・サービスの発送等を委託する配送業者に対し送付先情報として会員情報を開示する場合など利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
              </ul>
            </li>
          </ul>
          <h3>第7条 変更申請</h3>
          <ul>
            <li>1. 会員は当社に届け出た会員情報に変更があった場合は、当社所定の手続きに従って、速やかに変更申請を行うものとします。</li>
            <li>2. 前項に基づく変更申請が行われず、又は登録情報の不備により、当社からの現金振込や送付物の到着が遅れ、又は到達しない場合においても、会員宛に正常に処理がなされたものと判断し、その際に会員に損害が生じても当社は一切の責任を負わないものとします｡</li>
          </ul>
          <h3>第8条 会員資格の貸与・譲渡</h3>
          <p>
            会員は、理由の如何を問わず、ポイントサービスにおける権利・義務を他人に貸与譲渡、担保提供することはできないものとし、当該行為によって会員またはその他の第三者に損害が発生したとしても、当社は一切の責任を負わないものとします。また、当該行為によって当社及び第三者に損害が発生した場合は、当該行為を行った会員がその損害全額を賠償するものとします｡
          </p>
          <h3>第9条 会員資格の一時停止・取消</h3>
          <ul>
            <li>
              1. 会員が以下のいずれかの項目に該当すると当社が判断した場合、当社は当該会員の会員資格を事前通知のなく一時停止または取り消し出来ることとします。会員は、会員資格が取り消された場合には、取消時における全てのポイント、特典及び本サービス利用に関する一切の権利を失うものとします。なお、会員資格の一時停止又は取消しにより当社及び第三者に損害が発生した場合は、当該会員がその損害全額を賠償するものとします｡
              <ul>
                <li>① 会員が1年以上、本サービスの利用を行った形跡が認められないと当社が判断した場合</li>
                <li>② IDまたはパスワードを不正に使用し、または使用させた場合</li>
                <li>③ 当社が提供する情報を当社の承諾を得ることなく改変した場合</li>
                <li>④ 不正の目的をもって本サービスを利用し、または他のユーザーまたは第三者に利用させた場合</li>
                <li>⑤ 不正もしくは不正の恐れがある場合、または第三者による不正の防止を行なうために必要な場合</li>
                <li>⑥ 本規約のいずれかの条項に違反した場合</li>
                <li>⑦ 会員について、差押、仮差押、仮処分、滞納処分、強制執行、破産、民事再生の申し立てがなされた場合</li>
                <li>⑧ 当社又は加盟サイトに届け出た情報に事実と異なる内容があった場合</li>
                <li>⑨ 本サービスの運営を妨害した場合</li>
                <li>⑩ 不正な手段によってポイントを取得した場合</li>
                <li>⑪ 会員が反社会的勢力の団体員及び団体関係者に該当する場合</li>
                <li>⑫ その他、当社が会員として不適当と判断した場合</li>
              </ul>
            </li>
            <li>2. 会員の本サービス利用に関して疑義が生じた場合、当社の判断により会員資格の一時停止、本サービスの利用の制限をさせていただくことがあります。</li>
          </ul>
          <h3>第10条 退会</h3>
          <ul>
            <li>1. 会員は、自己の自由な意思で随時退会できるものとし、退会に際しては、当社所定の方法による退会の申請手続を取るものとします。</li>
            <li>2. 退会時に残存しているポイントや本サービスに関するすべての権利は、退会と同時に消滅するものとします。また、退会後<span>１～２か月程度</span>は入会いただいていた情報にて再入会いただくことはできません。</li>
            <li>3. 会員が死亡した場合、会員について、破産手続開始、民事再生手続開始の申立てがなされた場合には、その時点で退会したものとみなします｡</li>
          </ul>
          <h3>第11条 広告の配信</h3>
          <ul>
            <li>1． 当社は、ポイント提携サイト又はその他の広告主の代わりに、本サービスを通じて、会員に対して広告を配信することができるものとします｡</li>
            <li>2． 当社が提供するポイント提携サイト又はその他の広告主に関する情報については、明示されていると否とにかかわらず、その情報の市場性、目的適合性、権限、適法性、安全性、正確性等に関しいかなる保証も行わないものとします。</li>
          </ul>
          <h3>第12条 ポイントの付与</h3>
          <ul>
            <li>1． 会員は、本サービスにおいて、当社およびポイント提携サイトが成果と承認した場合、当社が発行するポイントを取得することができます。</li>
            <li>2． 成果とは、本サービスを通じての当社又はポイント提携サイトの提供する商品・サービスの購入、マーケティング活動・調査等への応諾、ゲーム等への参加などをいいます。</li>
            <li>3． 成果と承認されるための一定の条件は、当社とポイント提携サイトとの個別の取り決めによって決定されるものとし、成果へ該当するかは当社またはポイント提携サイトにおいて判断するものとします。</li>
            <li>4． 成果と類似の行為が確認された場合でも、上記の条件に基づき、当社およびポイント提携サイトにより成果と承認されない場合はポイントは付与されず、また、当社およびポイント提携サイトは、その理由についての説明責任は負わないものとします。</li>
            <li>5． 成果と承認された場合においても、その後に不正や虚偽などが発覚した場合は、付与したポイントを過去に遡及して取り消すことが出来ます。</li>
            <li>6． 当社は、会員が獲得又は取消されたポイントが、プログラムやシステムの不具合によって正しく反映されなかった場合は、当社の権限により当該ポイントを調整し、正確なポイント表示に変更することができるものとします｡</li>
          </ul>
          <h3>第13条 ポイント交換</h3>
          <ul>
            <li>1. 会員は、本サービスにおいて保有する当社発行のポイントを、当社所定の手続き・条件において、当社が定めるポイント交換先パートナーが発行するポイント、サービス、商品等と、交換することができます。</li>
            <li>2. ポイントの交換は第三者が行うことは出来ません。</li>
            <li>3. 当社はポイント交換の条件等を自由に設定・変更・抹消できるものとします。</li>
            <li>4. 会員が獲得したポイントから発生する税･ポイントで購入した製品やサービスにかかる税金･その他の費用は、会員の負担となります｡</li>
          </ul>
          <h3>第14条 ポイントの取消</h3>
          <p>以下のいずれかの事項に該当する場合、当社は会員のポイントの一部または全部を取り消すことができます。また、取り消されたポイントに対して当社は一切の保証を行いません。なお、すでにポイントの交換がされている場合、会員は過去に遡及して金品の返還義務が発生し、かかる損害について賠償の義務を負うものとします。</p>
          <ul>
            <li>① 最終のログインから、または最終ポイント取得日から180日が経過して、ポイントの獲得やポイントの交換がされていない場合</li>
            <li>② 本規約に基づきユーザー資格の停止、取消を行った場合</li>
            <li>③ 会員が本規約に違反したと当社が判定する場合</li>
            <li>④ 会員が不正な手段によってポイントを取得した場合</li>
            <li>⑤ ポイント提携サイトおよびその運営会社が会員に付与したポイントの取り消しを求め、当社がその要求を正当と判断した場合</li>
            <li>⑥ システムの故障等により、当社が意図せぬポイントが会員に付与された場合 </li>
            <li>⑦ その他、当社が会員に付与されたポイントを取り消すことが適当と判断した場合</li>
          </ul>
          <h3>第15条 禁止事項</h3>
          <p>会員は、本サービスを利用するにあたり以下のいずれの事項も行うことはできません。</p>
          <ul>
            <li>① 本サービスを不正の目的をもって利用したり、営利を目的とした利用をすること</li>
            <li>② 当社、ポイント提携サイトまたは第三者の商標権、著作権、意匠権、特許権などの知的財産権及びその他の権利を侵害する行為、またはそのおそれのある行為</li>
            <li>③ 第三者の財産、プライバシーもしくは肖像権を侵害する行為、または侵害するおそれのある行為</li>
            <li>④ 第三者を差別又は誹謗中傷し、名誉・信用を毀損する行為</li>
            <li>⑤ 詐欺等の犯罪に結びつく、または結びつくおそれのある行為</li>
            <li>⑥ 公序良俗に反する行為又はそのおそれのある行為</li>
            <li>⑦ 同一人物が、複数のユーザー登録を行う行為</li>
            <li>⑧ 実在又は架空の第三者になりすまして本サービスを利用する行為</li>
            <li>⑨ 当社又は第三者の情報を改ざん、消去する行為</li>
            <li>⑩ 有害なコンピュータプログラム等を送信し、または他者が受信可能な状態におく行為</li>
            <li>⑪ 第三者の電子メールアドレスを登録する等当社に対して虚偽の申告、届出を行う行為</li>
            <li>⑫ 上記各号の他、法令、この規約もしくは公序良俗に反する行為、本サービスの運営を妨害する行為、当社の信用を毀損し、もしくは当社の財産を侵害する行為、または他者もしくは当社に不利益を与える行為</li>
          </ul>
          <h3>第16条 本サービスの変更、一時的な中断、または中止</h3>
          <ul>
            <li>1. 当社は、事前に通知することなく、本サービスの諸条件･運用規則、又は本サービスの内容を変更することがあり、会員はこれを承諾するものとします｡当該変更には、本サービスの内容の部分的な改廃などを含みますが、これらに限定されません｡</li>
            <li>
              2. 当社は、次の各号に該当する場合には、ユーザーに事前に連絡することなく一時的に本サービスの運営または当社のウェブサイトの一部もしくは全部を中断・停止することがあります。
              <ul>
                <li>① 本サービスのシステムの保守、点検、修理、変更を定期的にまたは緊急に行う場合</li>
                <li>② 火災、停電などや、地震、噴火、洪水、津波などの天災により、本サービスの提供が困難な場合</li>
                <li>③ 戦争、変乱、暴動、争乱、労働争議などにより本サービスの提供ができなくなった場合</li>
                <li>④ 第三者による本サービスのシステムの破壊や妨害行為(データやソースコードの改ざん・破壊を含む。)などにより運営ができなくなった場合</li>
                <li>⑤ その他当社が本サービスの一時的な中断・停止を必要と判断した場合</li>
              </ul>
            </li>

            <li>3. 当社は、その事業上の必要により、ユーザーに事前に連絡することなく、本サービスの全部または一部の提供を中止することがあります。</li>
            <li>4. 当社は本条に基づき本サービスが変更、中断・停止または中止となったとしても、これに起因するユーザーまたは他の第三者が被ったいかなる不利益、損害について一切の責任を負いません。</li>
          </ul>
          <h3>第17条 提供情報の保証</h3>
          <p>当社が提供する提携サイト等に関する情報については、明示されていると否とにかかわらず、その情報の市場性、目的適合性、権限、適法性、安全性、正確性等に関しいかなる保証も行いません。</p>
          <h3>第18条 免責事項</h3>
          <ul>
            <li>1． 当社は、本サービスの利用により発生した会員の損害すべてに対し、いかなる責任も負わないものとし、当該損害の賠償をする義務もないものとします。当該損害には、ポイント提携サイトと会員による取引等によって発生する損害も含みます。</li>
            <li>2． 会員が本サービスの利用によって他の会員や第三者に対して損害を与えた場合、当社は当該会員に対して相応の損害賠償の請求を行うことができるものとします。</li>
            <li>3． 当社が会員の登録した情報を削除し、会員資格を停止、抹消し、本サービスを停止、中断、中止等したことにつき、当社は事由の如何を問わず一切の損害賠償義務を負わないものとします。</li>
          </ul>
          <h3>第19条 著作権等</h3>
          <ul>
            <li>1． 本サービスを構成する画面及び本サービスに関する著作権は当社に帰属しており、これを複製、頒布、譲渡、貸与、翻訳、使用許諾、転載、商品化、再利用等する行為は法律及び著作権に関する条約により禁じられています。</li>
            <li>2． 本サービスに関する特許権、実用新案権、商標権、意匠権、著作権その他の知的財産権は全て当社に帰属しておりこれらを侵害する行為は法律で禁止されています。</li>
          </ul>
          <h3>第20条 規約の範囲及び変更</h3>
          <ul>
            <li>1． 社は、会員の承諾を得ることなくこの規約及びその他の諸条件をいつでも変更することができます。この場合には、サービスの利用条件は、変更後の規約及びその他の諸条件によります。変更後の規約及びその他の諸条件については、当社が別途定める場合を除いて、当社のウェブサイト上に表示した時点より、効力を生じます。</li>
            <li>2． 当社が本サービスに掲載し、またはその他の方法により規定する個別規程及び当社が随時会員に対して通知する追加規程は、本規約の一部を構成するものとします｡また、本規約の定めと個別規程及び追加規程の定めが異なる場合には、個別規程及び追加規程の定めを優先させるものとします｡</li>
            <li>3． 当社が前項に基づき告知した変更の効力発生日の到来後、会員が本サービスを利用した場合は、会員は本規約の変更に同意したものとみなします。</li>
          </ul>
          <h3>第21条 損害賠償</h3>
          <ul>
            <li>1． 会員が本サービスの利用によってポイント提携サイトおよびその運営会社、ポイント交換先、他の会員や第三者に対して損害を与えた場合、会員は、自己の責任と費用をもって解決し、当社に損害を与えることがないものとします｡</li>
            <li>2． 会員が本規約に反した行為、又は不正若しくは違法な行為によって、当社及びポイント提携サイトに損害を与えた場合、当社は、当該会員に対して、当該損害賠償の請求を行うことができるものとします｡</li>
          </ul>
          <h3>第22条 準拠法</h3>
          <p>本規約に関する準拠法は、日本法が適用されるものとします｡</p>
          <h3>第23条 専属的合意管轄裁判所</h3>
          <p>会員と当社の間で訴訟の必要が生じた場合、当社の本店所在地を管轄する地方裁判所または簡易裁判所を専属的合意管轄裁判所とします。</p>
        </div>
      </div>
    </div>

    <!--info-->
    <div class="info">
      <div class="info__top">
        <p>個人情報の取扱いについて</p>
        <a @click.prevent="redirectURL('/policy')">
          別タブで開いて読む
          <ChevronRightIcon size="24"/>
        </a>
      </div>

      <div class="info__content policy">
        <div class="block">
          <h3>1. 個人情報の定義</h3>
          <p>個人情報とは、生存する個人に関する情報で、その情報に含まれる氏名や生年月日等によって特定の個人を識別できる情報、ならびに指紋、DNA、顔の骨格などの身体の特徴データや、マイナンバー、パスポートや運転免許証の番号などの個人識別符号を含む情報を、「個人情報」と認識します。</p>
        </div>

        <div class="block">
          <h3>2. 個人情報利用目的</h3>
          <p>ご提供いただきました個人情報は、以下の目的でのみ利用いたします。</p>
          <ul>
            <li>① お問い合わせに対する回答及び資料送付のご連絡</li>
            <li>② 当社のお客様向けサービスの提供</li>
            <li>③ 当社のお客様向けサービスの利用状況の分析</li>
            <li>④ 本人確認</li>
            <li>⑤ サービスの開発・改善のための分析</li>
            <li>⑥ 当社のお客様向けサービスに関する広告の配信のため</li>
          </ul>
        </div>

        <div class="block">
          <h3>3. 個人情報利用の制限</h3>
          <p>あらかじめご本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。ただし、次の場合はこの限りではありません。</p>
          <ul>
            <li>① 法令に基づく場合</li>
            <li>② 人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
            <li>③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
            <li>④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
          </ul>
        </div>

        <div class="block">
          <h3>4. 個人情報の適正な取得</h3>
          <p>個人情報の取得に際しては、利用目的、取扱い範囲を明確にし、ご本人の同意がある場合、適法かつ公正な手段によって取得します。</p>
        </div>

        <div class="block">
          <h3>5. 個人情報の取得に際する利用目的の通知</h3>
          <p>個人情報を取得するにあたり、あらかじめその利用目的を公表します。ただし、次の場合はこの限りではありません。</p>
          <ul>
            <li>① 利用目的をご本人に通知し、または公表することによりご本人または第三者の生命、身体、財産その他の 権利利益を害するおそれがある場合</li>
            <li>② 利用目的をご本人に通知し、または公表することによりChibiJobの権利または正当な利益を害するおそれがある場合</li>
            <li>③ 国の機関もしくは地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的をご本人に通知し、または公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
            <li>④ 取得の状況からみて利用目的が明らかであると認められる場合</li>
          </ul>
        </div>

        <div class="block">
          <h3>6. 個人情報利用目的の変更</h3>
          <p>個人情報の利用目的を変更する場合には、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲を超えては行わず、変更された利用目的について、ご本人に通知し、または公表します。</p>
        </div>

        <div class="block">
          <h3>7. 個人情報の安全管理・従業員の監督</h3>
          <p>個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理が図られるよう、個人情報保護規程を定め、従業員に対する必要かつ適切な監督を行います。</p>
        </div>

        <div class="block">
          <h3>8. 委託先の監督</h3>
          <p>個人情報の取扱いの全部又は一部を委託する場合は、委託先と機密保持を含む契約の締結、または、当社が定める約款に合意を求め、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。</p>
        </div>

        <div class="block">
          <h3>9. 第三者提供の制限</h3>
          <p>次に掲げる場合を除くほか、あらかじめご本人の同意を得ないで、個人情報を第三者に提供しません。</p>
          <ul>
            <li>① 法令に基づく場合</li>
            <li>② 人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
            <li>③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
            <li>④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
          </ul>
          <p>ただし次に掲げる場合は上記に定める第三者には該当せず、情報提供される場合があります。</p>
          <ul>
            <li>⑤ 商品・サービスの発送等を委託する配送業者に対し送付先情報として会員情報を開示する場合など利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
          </ul>
        </div>

        <div class="block">
          <h3>10. 個人情報の開示</h3>
          <p>ご本人から、個人情報の開示を求められたときは、ご本人に対し、遅滞なく開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。 なお、アクセスログなどの個人情報以外の情報については、原則として開示いたしません。</p>
          <ul>
            <li>① ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
            <li>② 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
            <li>③ 他の法令に違反することとなる場合</li>
          </ul>
        </div>

        <div class="block">
          <h3>11. 個人情報の訂正等</h3>
          <p>ご本人から、個人情報が真実でないという理由によって、内容の訂正、追加または削除（以下「訂正等」といいます）を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨ご本人に通知します。</p>
        </div>

        <div class="block">
          <h3>12. 個人情報の利用停止等</h3>
          <p>ご本人から、ご本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、または偽りその他不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ご本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。</p>
        </div>

        <div class="block">
          <h3>13. お問い合わせ</h3>
          <p>ChibiJobのプライバシーポリシーに関するお問い合わせは、下記担当までお願い致します。</p>
          <p>136-0072<br>
            東京都江東区大島1-9-8<br>
            contact@chibijob.com<br>
            株式会社ディー・エム広告社 ChibiJob担当</p>
        </div>

        <div class="block">
          <p class="text-right">
            株式会社ディー・エム広告社<br>
            代表取締役 藤本耕平</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons'
import CommonMixin from '@/mixins/common.mixin'

export default {
  name: 'TermPolicy',

  components: {
    ChevronRightIcon
  },

  mixins: [CommonMixin],

  data () {
    return {
      hostName: window.location.origin
    }
  },

  methods: {
    redirectURL (url) {
      const urlRedirect = this.hostName + url
      if (this.checkWebview()) {
        this.triggerEventApp('OPEN_WEBVIEW_INTERNAL', {
          url: urlRedirect
        })
      } else window.open(urlRedirect, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  @include font-size(14px);
  margin-bottom: 35px;
  &__top {
    display: flex;
    align-items: center;
    font-family: $font-family-title;
    margin-bottom: 16px;
    padding: 0 24px;
    @media screen and (max-width: 768px) {
      p {
        display: none;
      }
    }
    a {
      display: flex;
      align-items: center;
      margin-left: auto;
      color: #282828;
      @media screen and (max-width: 768px) {
        margin-left: inherit;
        font-size: 16px;
      }
    }
  }
  &__content {
    background: #ffffff;
    border-radius: 6px;
    padding: 18px 24px;
    max-height: 160px;
    overflow: auto;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
    }
    span {
      display: block;
      margin-top: 35px;
    }
    h3 {
      color: #7C934C;
      word-break: break-all;
    }
    .block {
      h3 {
        color: #7C934C;
        word-break: break-all;
      }
      p{
        padding-left: 15px;
        margin-bottom: 15px;
      }
      & > ul {
        padding-left: 10px;
        margin-bottom: 15px;
        li {
          text-indent: -1.2rem;
          margin-left: 1.2rem;
        }
        li > ul {
          padding-left: 15px;
        }
      }
    }
    .block:not(:last-child) {
      margin-bottom: 10px;
    }
    &.policy {
      .block {
        p {
          padding-left: 20px;
          margin-bottom: 15px;
          @media #{$info-phone} {
            padding-left: 15px;
          }
          & + ul {
            padding-left: 45px;
            @media #{$info-phone} {
              padding-left: 25px;
            }
            li {
              text-indent: -1.2rem;
              margin-left: 1.2rem;
            }
          }
        }
      }
    }
  }
}
</style>
